import React from "react"
import theme from '../../../../config/theme';
import Link from '../../../elements/Base/Link';

const Slide = (props) => {
    return (
        <div className="category-slide">
            <style jsx>{`
                .category-slide {
                    max-width: 210px;
                    height: 52px;
                    flex: 0 0 100%;
                    margin-right: 10px;
                    border-radius: 10px;
                    overflow:hidden;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .category-slide:hover .category-thumbnail {
                    filter: brightness(130%);
                    cursor: pointer;
                }

                .category-slide:first-of-type {
                    margin-left: 10px;
                }

                .category-slide:last-of-type {
                    margin-right: 10px;
                }

                .category-slide:last-of-type {
                    margin-right: 10px;
                }

                .category-thumbnail {
                    height: 80px;
                    background-color: ${theme.colors.base.gray};
                    background-size: cover;
                    background-image: url('${props.thumbnail}');
                    background-position: center;
                    transition: all 0.1s ease-in;
                }

                .category-text {
                    background-color:transparent;
                    color: ${theme.colors.base.white};
                    font-weight: 700;

                    letter-spacing: 1px;
                    text-transform: uppercase;
                    padding: 10px;
                    position: absolute;
                    top: 0px;
                    text-shadow: 0px 0px 20px #000000,  0px 0px 10px #000000, 0px 0px 5px #000000;
                    font-size: 1.1em;
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .category-thumbnail {
                        filter: brightness(130%);
                    }
                }


                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .category-slide {
                        max-width: 140px;
                    }

                    .category-slide {
                        height: 65px;
                    }

                    .category-text {
                        font-size: 1em;
                        padding: 5px;
                    }
                }
            `}</style>
            {props.href ? <Link href={props.href} as={props.as}>
                {props.thumbnail ?
                    <div className="category-thumbnail">

                    </div> : null}
                <div className="category-text">
                    {props.children}
                </div>
            </Link> : <>
                {props.thumbnail ?
                    <div className="category-thumbnail">

                    </div> : null }
                <div className="category-text">
                    {props.children}
                </div>
            </>}
        </div>
    )
}

export default Slide;
