import React from "react"
import theme from '../../../../config/theme';

const Header = (props) => {
    return (
        <div className="featured-products-header">
            <style jsx>{`
                .featured-products-header {
                    color: ${props.color || theme.colors.base.black};
                    font-size: 2.2em;
                    font-weight: 900;
                   
                    text-transform: uppercase;
                    margin-top: 3px;
                    margin-bottom: 3px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .featured-products-header {
                        font-size: 1.7em;
                    }
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .featured-products-header {
                        font-size: 1.4em;
                    }
                }
            `}</style>
            {props.title}
        </div>
    )
}

export default Header;