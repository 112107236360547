import React from "react"
import Jumbotron from "../components/blocks/Homepage/Jumbotron";
import Api from '../utils/api';
import CategorySlider from "../components/blocks/Homepage/CategorySlider";
import WidthWrapper from "../components/elements/Base/WidthWrapper";
import FeaturedProducts from "../components/blocks/Homepage/FeaturedProducts";
import InfoText from "../components/blocks/Homepage/InfoText";
import MetaElement from "../components/elements/MetaElement";
import StructuredData from "../components/elements/StructuredDataElement";

const Index = (props) => {
    return (
        <>
            <MetaElement pageId='index'/>
            <StructuredData type="LocalBusiness"/>
          
            <Jumbotron data={props.carousel}/>
            <CategorySlider categories={props.categories.map((category) => {
                return {
                    name: category.title,
                    slug: category.slug,
                    thumbnail: `${process.env.imageStorage}/category_thumbnail_default_${category.thumbnail}.jpg`,
                }
            })}/>
            
            {props.productLists.map((productList, index) => (
                <FeaturedProducts  
                    priority={index === 0}
                    index={index}
                    key={`featured-list-${productList.slug}`}
                    title={productList.name}
                    type={productList.type}
                    slug={productList.slug}
                    moreText="VEZI MAI MULT"
                    more={productList.hasMore}
                    href={productList.urlHref}
                    products={productList}
                />
                
            ))}
        </>
    )
}

Index.getInitialProps = async function(ctx) {
    const apiResponse = await Api.get('/public/homepage');

    const carousel = apiResponse.data.carousel || {};

    return {
        goro: ctx.query.goro === "da",
        carousel,
        categories: apiResponse.data ? apiResponse.data.categories : [],
        productLists: apiResponse.data ? apiResponse.data.productLists : [],
    }
}

export default Index;