import React from "react"
import { useState, useEffect } from 'react';

import Slider from 'react-slick';
import Slide from './Slide';
import Dot from './Dot';
import theme from '../../../../config/theme';
import ShowcaseSlide from './ShowcaseSlide';
import Link from "next/link";
import { useRouter } from 'next/router'

const Carousel = (props) => {
    const [centerPadding, setCenterPadding] = useState(0);
    const router = useRouter();

    let startPosition = [0, 0];
    let drift = null;

    function handleResize() {
        const newCenterPadding = window.innerWidth - process.env.fullWidth < 0 ? 0 : ((window.innerWidth - process.env.fullWidth) / 2)
        setCenterPadding(newCenterPadding);
    }

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        focusOnSelect: true,
        className: "center",
        centerMode: false,
        centerPadding: parseInt(centerPadding) + "px",
        dots: false,
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        customPaging: (i) => <Dot i={i} />
    }

    const routeToLink = (as, href) => {
        startPosition = [0, 0];
        if(drift > 10) {
            drift = 0;
            return;
        }
        drift = 0;
        router.push(href, as);
    }

    const linkMouseUp = (e) => {
        drift = Math.abs((startPosition[0] - e.clientX) + (startPosition[1] - e.clientY));
    }

    const linkMouseDown = (e) => {
        startPosition = [e.clientX, e.clientY]
    }

    return (
        <div className="carousel">
            <style jsx>{`
                .carousel {
                    margin-top: 0px;
                    margin-bottom: 10px;
                    position: relative;
                }

                .slide-container {
                    // padding: 10px 10px;
                    box-sizing: border-box;
                    overflow: hidden;
                }

                
                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .slide-container {
                        padding: 0;
                    }
                }

                .slide-container:active, .slide-container:focus {
                    outline: none;
                }

                :global(.slick-slide) {
                    opacity: 0.7;
                    transition: opacity 0.2s ease;
                    cursor: pointer;
                }

                :global(.slick-slide:hover) {
                    opacity: 1;
                }

                :global(.slick-slide.slick-active) {
                    opacity: 1;
                }

                .no-outline {
                    outline: none!important;
                }


            `}</style>
            <Slider {...settings}>
                {Array.isArray(props.data) ? props.data.map((slide, index) => (
                    slide.properties.hasFullLink ?
                        <div key={`carousel-slide-${index}`}
                            className="no-outline"
                            onMouseUp={(e) => linkMouseUp(e)}
                            onMouseDown={(e) => linkMouseDown(e)}
                            onClick={() => routeToLink(slide.properties.fullLinkTo, slide.properties.fullLinkHref)}>
                            <div className="slide-container" key={`carousel-${index}`}>
                                <Slide>
                                    <ShowcaseSlide data={slide} />
                                </Slide>
                            </div>
                        </div>: 
                        <div className="slide-container" key={`carousel-slide-${index}`}> 
                            <Slide>
                                <ShowcaseSlide data={slide} index={index}/>
                            </Slide>
                        </div>
                )) : null}
            </Slider>
        </div>
    )
}

export default Carousel;