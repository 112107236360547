import React from "react"
import Product from '../../../elements/Product/Product';

const GridLayout = (props) => {
    return (
        <div className="featured-products-grid">
            <style jsx>{`
                .featured-products-grid {
                    margin: 10px;
                    display: grid;
                    grid-template-columns: repeat(5, minmax(240px, 1fr));
                    grid-template-rows: repeat(${props.rows || '2'}, 1fr);
                    grid-auto-rows: 0;
                    overflow: hidden;
                    padding: 10px 10px;
                    box-sizing: border-box;
                }

                @media only screen and (max-width: 1250px) {
                    .featured-products-grid {
                        grid-template-columns: repeat(4, minmax(180px, 1fr));
                    }
                }

                @media only screen and (max-width: 840px) {
                    .featured-products-grid {
                        grid-template-columns: repeat(3, minmax(150px, 1fr));
                        padding: 0px 10px;
                    }
                }

                @media only screen and (max-width: 550px) {
                    .featured-products-grid {
                        padding: 0;
                        grid-template-columns: repeat(2, minmax(140px, 1fr));
                        grid-template-rows: repeat(${props.rows + 2 || '4'}, 340px);
                    }
                }

            `}</style>

            {props.products.map((product, index) => (
                <div className="regular-item" key={`featured-${index}`}>  
                    <Product 
                        priority={props.priority} 
                        showAddToCart={true} 
                        product={product} />
                </div>
            ))}
        </div>
    )
}

export default GridLayout;