import React from "react"
import theme from "../../../../config/theme"
import WidthWrapper from "../../../elements/Base/WidthWrapper"
import Button from "../../../elements/Base/Button"
import Head from "next/head"

const ShowcaseSlide = (props) => {
    return (
        <div className="slide-wrapper">
            <Head>
                <link rel="preload"
                    href={`${process.env.imageStorage}/carousel_bg_mobile_${props.data.backgroundImage}.jpg`}
                    imagesrcset={`${process.env.imageStorage}/carousel_bg_1080p_${props.data.backgroundImage}.jpg 1920w, 
                    ${process.env.imageStorage}/carousel_bg_mobile_${props.data.backgroundImage}.jpg 700w`} 
                    as="image"
                />
            </Head>
            <style jsx>{`
                .slide-wrapper {
                    width: 100%;
                    overflow: hidden;
                    background-color: ${props.data.backgroundColor};
                    height: 100%;
                    ${props.data.backgroundImage ? 
                       `background-image: url('${process.env.imageStorage}/carousel_bg_default_${props.data.backgroundImage}.jpg');` : null
                    }
                    background-position: center ${props.data.properties.backgroundAlignment}%;
                    background-size: cover;
                }

                @media only screen and (max-width: 1920px) {
                    .slide-wrapper {
                        ${props.data.backgroundImage ? 
                            `background-image: url('${process.env.imageStorage}/carousel_bg_1080p_${props.data.backgroundImage}.jpg');` : null
                        }  
                    }
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .slide-wrapper {
                        ${props.data.backgroundImage ? 
                            `background-image: url('${process.env.imageStorage}/carousel_bg_mobile_${props.data.backgroundImage}.jpg');` : null
                        }  
                    }
                }
            `}</style>
        </div>
    )
}

export default ShowcaseSlide;