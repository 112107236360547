import React from "react"
import Header from './Header';
import Button from '../../../elements/Base/Button';

import GridLayout from './GridLayout';
import SpotlightLayout from './SpotlightLayout';
import theme from '../../../../config/theme';
import { useRouter } from 'next/router'
import WidthWrapper from "../../../elements/Base/WidthWrapper";
import ButtonV3 from "../../../BaseElements/Button";

const FeaturedProducts = (props) => {
    const router = useRouter();
    if(!props.products) {
        return null;
    }

    const products = props.products.products;
    const double = props.slug === 'black-friday';
    let defaultRows = 2;

    if(products.length < 10) {
        defaultRows = 1;
    }

    return (
        <div className="featured-products">
            <style jsx>{`
                .more-button-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    marign: 0px;
                    margin-bottom: 20px;
                }

                .more-button {
                    max-width: 200px;
                    align-self: center;
                    padding: 10px;
                }

                .header-container {
                    margin-top: 20px;
                    padding:10px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    color: #FFFFFF;
                    background: linear-gradient(to right,${theme.colors.base.lightGray} 20%,${theme.colors.base.gray});
                    ${double ? `background-image: url('/img/featured-bg-offer.jpg'); text-shadow: 2px 2px #000000;`
                        : `background-image: url('/img/featured-bg.jpg');`}
                    background-size: auto 100%;
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
                    box-sizing: border-box;
                }

                @media only screen and (max-width: ${theme.responsive.s}px) { 
                    .more-button { 
                        width: 100%;
                        max-width: 100%;
                    }
                }


            `}</style>                               
            
           
            <div className="header-container">
                <Header color={double ?  "#FFFFFF" : theme.colors.base.base} title={props.title}/>
            </div>

            <WidthWrapper className="small">
                <div className="featured-products-content">   
                        <GridLayout index={props.index} rows={double ? 4 : defaultRows} priority={props.priority} products={products}/>
                </div>
                
                {props.more ? 
                    <div className="more-button-wrapper">
                        <div className="more-button">
                            <ButtonV3 href={props.href}  type="full" color="dark" shadow={true}>
                                {props.moreText}
                            </ButtonV3>
                        </div>
                    </div> : null}
            </WidthWrapper>
        </div>
    )
}

export default FeaturedProducts;