import React from "react"
import { useRef, useState, useEffect } from 'react';

import Slide from './Slide';
import ScrollContainer from 'react-indiana-drag-scroll';
import WidthWrapper from '../../../elements/Base/WidthWrapper';
import Icon from 'react-icons-kit';
import { chevronLeft } from 'react-icons-kit/feather';
import { chevronRight } from 'react-icons-kit/feather';
import theme from '../../../../config/theme';

const CategorySlider = (props) => {

    const [showRightHint, setShowRightHint] = useState(false);
    const [showLeftHint, setShowLeftHint] = useState(false);

    const scrollBox = useRef(null);

    useEffect(() => {
        handleScroll();

        window.addEventListener('resize', handleScroll);

        return (() => {
            window.removeEventListener('resize', handleScroll);
        })
    }, []);


    const handleScroll = () => {
        if (!scrollBox.current) {
            return;
        }

        const scrollElement = scrollBox.current.getElement();

        const scrollLeft = Math.ceil(scrollElement.scrollLeft);
        const width = scrollElement.offsetWidth;
        const scrollWidth = scrollElement.scrollWidth;

        if (scrollLeft <= 50) {
            setShowLeftHint(false);
        } else {
            setShowLeftHint(true);
        }

        if (scrollLeft + width >= scrollWidth - 50) {
            setShowRightHint(false);
        } else {
            setShowRightHint(true);
        }
    }

    const handleLeftClick = () => {
        if (!scrollBox.current) {
            return;
        }

        const scrollElement = scrollBox.current.getElement();

        scrollElement.scrollBy({
            left: -200,
            behavior: 'smooth'
        })

    }

    const handleRightClick = () => {
        if (!scrollBox.current) {
            return;
        }

        const scrollElement = scrollBox.current.getElement();

        scrollElement.scrollBy({
            left: 200,
            behavior: 'smooth'
        })
    }



    return (
        <>
            <style jsx>{`
                .custom-width-wrapper-container {
                    width: 100%;
                    display:flex;
                    justify-content: center;
                }

                .custom-width-wrapper {
                    width: 100%;
                    max-width: 1800px;
                }

                .category-slider-wrapper {
                    width: 100%;
                    position: relative;
                    z-index: 8000;
                }


                .category-slider {
                    display: flex;
                    justify-content: flex-start;
                }

                .slide-hint {
                    position: absolute;
                    height: 100%;
                    color: ${theme.colors.base.white};
                    display: flex;
                    align-items: center;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.1s ease-in;
                    z-index:8000;
                }

                .slide-hint.show {
                    visibility: visible;
                    opacity: 1;
                }

                .left {
                    top: 0;
                    left: 0;

                    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                }

                .right {
                    right: 0;
                    top: 0;

                    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                }
            `}</style>
            <div className="custom-width-wrapper-container">
                <div className="custom-width-wrapper">
                    <div className="category-slider-wrapper">

                        <div className={`slide-hint left ${showLeftHint ? 'show' : ''}`}
                            onClick={handleLeftClick}>
                            <Icon size={24} icon={chevronLeft} />
                        </div>

                        <div className={`slide-hint right ${showRightHint ? 'show' : ''}`}
                            onClick={handleRightClick}>
                            <Icon size={24} icon={chevronRight} />
                        </div>

                        <ScrollContainer onScroll={handleScroll} ref={scrollBox}>
                            <div className="category-slider">
                                <Slide as={'/toate-produsele'} href={'/toate-produsele'} thumbnail={`/img/toate-produsele.jpg`}>
                                    Toate Produsele
                                </Slide>
                                <Slide as={'/singles'} href={'/singles'} thumbnail={`/img/tcg-singles.jpg`}>
                                    Carti TCG
                                </Slide>
                                {props.categories.map((category, index) => (
                                    <Slide as={`/categorie/${category.slug}`} href={'/categorie/[slug]'} thumbnail={category.thumbnail} key={`category-slider-${index}`}>
                                        {category.name}
                                    </Slide>
                                ))}
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategorySlider;
