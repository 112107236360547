import React from "react"
import theme from '../../../../config/theme';

const Slide = (props) => {
    return (
        <div className="slide">
            <style jsx>{`
                .slide {
                    height: 150px;
                    overflow: hidden;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                }

                @media (min-width: ${theme.responsive.s}px) {
                    .slide {
                        height: 200px;
                    }
                }

                @media (min-width: ${theme.responsive.m}px) {
                    .slide {
                        height: 300px;
                    }
                }

                @media (min-width: ${theme.responsive.l}px) {
                    .slide {
                        height: 470px;
                    }
                }

                @media (min-width: 2000px) {
                    .slide {
                        height: 620px;
                    }
                }
            `}</style>
            {props.children}
        </div>
    )
}

export default Slide;