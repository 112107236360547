import React from "react"
import theme from "../../config/theme";
import Link from "next/link";

const buttonType = {
    default: {
        base: theme.colors.base.lightGray,
        text: theme.colors.base.base,
        border: theme.colors.base.lightGray,
    },
    dark: {
        base: ` linear-gradient(to right,${theme.colors.gradients.darkA} 10%,${theme.colors.gradients.darkB}) `,
        text: theme.colors.base.white,
        border: theme.colors.gradients.darkA
    },
    red: {
        base: `linear-gradient(to right,${theme.colors.gradients.redA} 10%,${theme.colors.gradients.redB})`,
        text: theme.colors.base.white,
        border: theme.colors.gradients.redA
    },
    orange: {
        base: `linear-gradient(to right,${theme.colors.gradients.orangeA} 10%,${theme.colors.gradients.orangeB})`,
        text: theme.colors.base.white,
        border: theme.colors.gradients.orangeA
    },
    blue: {
        base: `linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB})`,
        text: theme.colors.base.white,
        border: theme.colors.gradients.blueA
    },
    green: {
        base: `linear-gradient(to right,${theme.colors.gradients.greenA} 10%,${theme.colors.gradients.greenB})`,
        text: theme.colors.base.white,
        border: theme.colors.gradients.greenA
    },
    purple: {
        base: `linear-gradient(to right,${theme.colors.gradients.purpleA} 10%,${theme.colors.gradients.purpleB})`,
        text: theme.colors.base.white,
        border: theme.colors.gradients.purpleA
    },
    disabled: {
        base: theme.colors.base.darkGray,
        text: theme.colors.base.lightGray,
        border: theme.colors.base.darkGray
    }
}

const Button = React.forwardRef((props, ref) => {
    const colorScheme = buttonType[props.color] ? buttonType[props.color] : buttonType['default']; 

    return (
        <>
            <style jsx>{`
            button {
                background: ${colorScheme.base};
                color: ${colorScheme.text};
                border-color: ${colorScheme.border};
                font-size: ${theme.font.text};
                box-sizing: border-box;
                border: 0;
                width: ${props.width ? (props.width + "px") : "100%"};
                padding: 0px ${props.padding === false ? "0px" : "10px"};
                height: ${props.height ? (props.height + "px") : "48px"};
                font-weight: 700;         
                transition: 0.1s;         
                overflow: hidden;
                box-sizing:border-box;
                border-style: solid;

                display: flex;
                align-items: center;
                justify-content: center;

                ${props.disabled === true ? `cursor: not-allowed;` : `cursor: pointer;`}
                ${props.rounded !== false ? `border-radius: 4px;` : ``}

                ${props.uppercase === true ? `text-transform: uppercase;` : ``}
            }


            button.outlined {
                border-width: 2px;
                background: transparent;
                color: ${colorScheme.border};
            }

            button.transparent {
                border-width: 0px;
                background: transparent;
                color: ${colorScheme.border};
            }

            button:active {
                filter: brightness(130%);
                box-shadow: 0px 0px 5px ${colorScheme.border};
            }        
            
            button:hover {
                filter: brightness(120%);
            } 

        `}</style>

            <button ref={ref}
                className={`${props.type}`}
                type={props.submit ? 'submit' : 'button'}
                onClick={props.onClick}
                disabled={props.disabled}>
                {props.children}
            </button>
        </>
    )

})

export default React.forwardRef((props, ref) => (
    props.href ?
        <Link href={props.href} as={props.as || props.href}>
            <a>
                <style>{`
                    a {
                        width: 100%;
                    }
                `}</style>
                <Button {...props} ref={ref}>
                    {props.children}
                </Button>
            </a>
        </Link> :
        <Button {...props} ref={ref}>
            {props.children}
        </Button>

));