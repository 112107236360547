import React from "react"
import theme from '../../../../config/theme';
import Carousel from './Carousel';

const Jumbotron = (props) => {
    return (
        <div className="jumbotron-wrapper">
            <style jsx>{`
                .jumbotron-wrapper {
                    margin-bottom: 0px;
                }

                 @media only screen and (max-width: ${theme.responsive.m}px) {
                    .jumbotron-wrapper {
                        margin-bottom: 10px;
                    }
                 }
            `}</style>
            <Carousel data={props.data}/>
        </div>
    )
}

export default Jumbotron;