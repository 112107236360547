import React from "react"
import theme from '../../../../config/theme';

const Dot = (props) => (
    <div className="dot-container" onClick={props.onClick}>
        <style jsx>{`
            .dot-container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-basis:1;
            }
            .dot {
                width: 100%;
                height: 6px;
                background-color: ${theme.colors.base.secondary};
                opacity: 1;
            }

            :global(.slick-active) .dot {
                opacity: 1;
            }
        `}</style>
        <div className="dot">
        </div>
    </div>
)

export default Dot;